import type { DataProxy } from 'apollo-cache';

import type { AnnotationInfo } from '@atlaskit/editor-plugins/annotation';

import type { MutationResult } from '@confluence/inline-comments-common/entry-points/inlineCommentsTypes';
import {
	InlineCommentQuery,
	type InlineCommentQueryType,
	type InlineCommentQueryNode,
	type CreateInlineReplyMutationType,
} from '@confluence/inline-comments-queries';
import {
	ActiveCommentsQuery,
	type ActiveCommentsQueryType,
	type ActiveCommentsPanelNode,
	type GraphQLContentStatus,
} from '@confluence/comments-panel-queries';
import { CommentCreationLocation } from '@confluence/inline-comments-queries';
import type { ExperienceTrackerAPI } from '@confluence/experience-tracker';
import { REPLY_TO_INLINE_COMMENT_EXPERIENCE } from '@confluence/experience-tracker';
import { InlineCommentFramework } from '@confluence/inline-comments-common/entry-points/enum';
import { getLogger } from '@confluence/logger';
import { END } from '@confluence/navdex';
import type { PageMode } from '@confluence/page-utils/entry-points/enums';
import { fg } from '@confluence/feature-gating';

import { getTargetNodeType } from './analyticsUtils';

const logger = getLogger('inline-comments-common');

const removeReplyFromCache = (
	parentComment: ActiveCommentsPanelNode | InlineCommentQueryNode,
	replyId: string,
) => {
	if (parentComment) {
		// Remove the returned commentId from the cache and reset it
		const idxToRemove = parentComment.replies.findIndex((reply) => reply?.id === replyId);

		if (idxToRemove !== -1) {
			parentComment.replies.splice(idxToRemove, 1);
		}
	}
};

const addReplyToCache = (
	parentComment: ActiveCommentsPanelNode | InlineCommentQueryNode,
	newData: CreateInlineReplyMutationType,
	isCommentsPanel?: boolean,
) => {
	if (parentComment) {
		const mutationResult = newData.replyInlineComment;
		// Add the new reply entry to the cache and reset it

		if (isCommentsPanel) {
			(parentComment as ActiveCommentsPanelNode).replies.push(mutationResult);
		} else {
			(parentComment as InlineCommentQueryNode).replies.push(mutationResult);
		}
	}
};

export const updateApolloCacheCallback =
	(
		actionType: 'delete' | 'create',
		queryVariables: {
			pageId: string;
			contentStatus: GraphQLContentStatus[];
			annotationId?: string;
		},
		parentCommentId?: string | null,
		commentId?: string,
	) =>
	(cache: DataProxy, result: MutationResult) => {
		// If the mutation fails, don't do anything
		if (!result || !result.data) {
			return;
		}

		if (fg('confluence_frontend_comments_panel_v2')) {
			try {
				const commentsPanelVariables = {
					pageId: queryVariables.pageId,
					contentStatus: queryVariables.contentStatus,
				};
				const commentsPanelResponse = cache.readQuery<ActiveCommentsQueryType>({
					query: ActiveCommentsQuery,
					variables: commentsPanelVariables,
				});

				if (commentsPanelResponse) {
					const commentsPanelData = { ...commentsPanelResponse };
					const commentsPanelParentComment = commentsPanelData?.comments?.nodes?.find(
						(comment) => comment?.id === parentCommentId,
					);

					if (commentsPanelParentComment) {
						if (actionType === 'delete') {
							removeReplyFromCache(commentsPanelParentComment, commentId!);
						} else {
							addReplyToCache(
								commentsPanelParentComment,
								result.data as CreateInlineReplyMutationType,
								true,
							);
						}
					}

					// Update the comments panel query
					cache.writeQuery({
						query: ActiveCommentsQuery,
						variables: commentsPanelVariables,
						data: commentsPanelData,
					});
				}
			} catch (err) {
				logger.error`An Error occurred when updating comments panel cache for ${actionType} reply - ${err}`;
			}
		}

		try {
			const inlineQueryVariables = { ...queryVariables };
			const inlineCommentResponse = cache.readQuery<InlineCommentQueryType>({
				query: InlineCommentQuery,
				variables: inlineQueryVariables,
			});

			if (inlineCommentResponse) {
				const inlineCommentData = { ...inlineCommentResponse };
				const inlineCommentsParentComment = inlineCommentData?.comments?.nodes?.find(
					(comment) => comment?.id === parentCommentId,
				);

				if (inlineCommentsParentComment) {
					if (actionType === 'delete') {
						removeReplyFromCache(inlineCommentsParentComment, commentId!);
					} else {
						addReplyToCache(
							inlineCommentsParentComment,
							result.data as CreateInlineReplyMutationType,
						);
					}
				}

				// Update the inline comments
				cache.writeQuery({
					query: InlineCommentQuery,
					variables: inlineQueryVariables,
					data: inlineCommentData,
				});
			}
		} catch (err) {
			logger.error`An Error occurred when updating inline comments cache for ${actionType} reply - ${err}`;
		}
	};

export const getCommentCreationLocation = (isEditor: boolean, isLivePage: boolean) => {
	if (isLivePage) return CommentCreationLocation.LIVE;
	if (isEditor) return CommentCreationLocation.EDITOR;

	return CommentCreationLocation.RENDERER;
};

interface HandleCreateInlineReplySuccessProps {
	onSuccess: any;
	editCommentQueryId: string | undefined;
	setCommentForEdit: any;
	data: any;
	createAnalyticsEvent: any;
	pageId: string | undefined;
	pageType: string;
	analyticsSource: string;
	commentId: string | undefined;
	pageMode: PageMode;
	selectedAnnotation: AnnotationInfo | undefined;
	getInlineNodeTypes: any;
	experienceTracker: ExperienceTrackerAPI;
	isEditor: boolean;
}

export const handleCreateReplySuccess = ({
	onSuccess,
	editCommentQueryId,
	setCommentForEdit,
	data,
	createAnalyticsEvent,
	pageId,
	pageType,
	analyticsSource,
	commentId,
	pageMode,
	selectedAnnotation,
	getInlineNodeTypes,
	experienceTracker,
	isEditor,
}: HandleCreateInlineReplySuccessProps) => {
	onSuccess();
	if (editCommentQueryId) {
		setCommentForEdit('');
	}

	const replyCommentInfo = data?.replyInlineComment;

	const markerRef = replyCommentInfo?.location?.inlineMarkerRef;

	createAnalyticsEvent({
		type: 'sendTrackEvent',
		data: {
			action: 'created',
			actionSubject: 'comment',
			actionSubjectId: replyCommentInfo?.id, // The newly created comment ID
			objectType: pageType,
			objectId: pageId,
			source: analyticsSource,
			attributes: {
				commentType: 'inline',
				parentCommentId: commentId ?? null, // analytics event schema type expects string or null
				mode: pageMode,
				framework: InlineCommentFramework.ANNOTATION_PROVIDER,
				navdexPointType: END,
				inlineNodeTypes:
					selectedAnnotation && getInlineNodeTypes
						? getInlineNodeTypes(selectedAnnotation.id)
						: undefined,
				targetNodeType: getTargetNodeType(markerRef, isEditor),
			},
		},
	}).fire();

	experienceTracker.succeed({
		name: REPLY_TO_INLINE_COMMENT_EXPERIENCE,
	});
};

export const insertReopenedCommentAnnotation = (
	allAnnotations: string[],
	commentMarkerRef: string,
	previousOrderedActiveAnnotations: string[],
	setOrderedActiveAnnotationIdList: (annotations: string[]) => void,
) => {
	const indexInAnnotations = allAnnotations.indexOf(commentMarkerRef);

	if (indexInAnnotations === -1) {
		throw new Error('Marker ref is not present in the annotations list');
	}

	const insertIndex = previousOrderedActiveAnnotations.findIndex(
		(annotation) => allAnnotations.indexOf(annotation) > indexInAnnotations,
	);

	const finalInsertIndex =
		insertIndex === -1 ? previousOrderedActiveAnnotations.length : insertIndex;

	const updatedOrderedActiveAnnotations = [
		...previousOrderedActiveAnnotations.slice(0, finalInsertIndex),
		commentMarkerRef,
		...previousOrderedActiveAnnotations.slice(finalInsertIndex),
	];
	setOrderedActiveAnnotationIdList(updatedOrderedActiveAnnotations);
};
