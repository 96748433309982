import { defineMessages } from 'react-intl-next';

export const i18n = defineMessages({
	invalidHighlight: {
		id: 'inline-comments-common.error.invalid.highlight',
		defaultMessage: 'We’re having trouble adding your comment. Try highlighting more or less text.',
		description:
			'A message informing the user we had issues adding their comment and to select more/less text',
	},
	staleContent: {
		id: 'inline-comments-common.error.stale.content',
		defaultMessage: 'Refresh to see the latest updates, and try adding your comment again.',
		description: 'A message informing the user that the content is old and needs to be refreshed',
	},
	viewNotPermitted: {
		id: 'inline-comments-common.error.view.not.permitted',
		defaultMessage:
			'Your permission settings don’t allow you to view the comment. Check with your administrator if you need this ability.',
		description: "A message informing the user they don't have permissions to view comments",
	},
	createNotPermitted: {
		id: 'inline-comments-common.error.create.not.permitted',
		defaultMessage:
			'You can’t create comments in this space. A space admin can modify your permissions.',
		description: "A message informing the user they don't have permissions to create comments",
	},
	contentHasBeenRemoved: {
		id: 'inline-comments-common.error.create.content.removed',
		defaultMessage:
			'The text you highlighted has already been deleted by someone else. Refresh to see the latest update.',
		description:
			'A message informing the user they cannot create a comment because the content has been removed by another user',
	},
	deleteNotPermitted: {
		id: 'inline-comments-common.error.delete.not.permitted',
		defaultMessage:
			'You can’t delete comments in this space. A space admin can modify your permissions.',
		description: "A message informing the user they don't have permissions to delete comments",
	},
	deleteWithRepliesNotAllowed: {
		id: 'inline-comments-common.error.delete.with.replies',
		defaultMessage:
			'You can’t delete this comment anymore because someone else replied to it. Refresh to see all replies.',
		description:
			'An error message informing the user they cannot delete this inline comment because someone has replied to it. They should reload the page to see the reply.',
	},
	commentAlreadyDeleted: {
		id: 'inline-comments-common.error.comment.already.deleted',
		defaultMessage:
			'This comment has already been deleted by someone else. Refresh to see the latest update.',
		description:
			'An error message informing the user they cannot delete this inline comment because it has already been deleted. They should reload the page to see the reply.',
	},
	resolveNotPermitted: {
		id: 'inline-comments-common.error.resolve.not.permitted',
		defaultMessage:
			'You can’t resolve comments in this space. A space admin can modify your permissions.',
		description: "A message informing the user they don't have permissions to resolve comments",
	},
	editNotPermitted: {
		id: 'inline-comments-common.error.edit.not.permitted',
		defaultMessage:
			'You can’t edit comments in this space. A space admin can modify your permissions.',
		description: "A message informing the user they don't have permissions to edit comments",
	},
	replyNotPermitted: {
		id: 'inline-comments-common.error.reply.not.permitted',
		defaultMessage:
			'You can’t reply to comments in this space. A space admin can modify your permissions.',
		description: "A message informing the user they don't have permissions to add replies",
	},
	replyNotAllowedParentDeleted: {
		id: 'inline-comments-common.error.reply.not.allowed.parent.deleted',
		defaultMessage:
			'You can’t reply to this comment because it was just deleted by someone else. Refresh to see the latest update.',
		description:
			'An error message telling the user they cannot reply to a comment because it has already been deleted',
	},
	unableToMarkDOM: {
		id: 'inline-comments-common.error.unable.to.mark.dom',
		defaultMessage: 'Your comment has been successfully created. Refresh to view it.',
		description:
			'A message to tell the user their comment is created, but the mark could not be added to the DOM',
	},
	unexpectedError: {
		id: 'inline-comments-common.error.unexpected',
		defaultMessage:
			'Something went wrong when saving this comment. Close the comment or refresh, and try adding your comment again.',
		description: 'A message explaining an unexpected error occurred',
	},
	actionNotPermittedTitle: {
		id: 'inline-comments-common.error.action.not.permitted.title',
		defaultMessage: 'Unauthorized Action',
		description:
			"A title for the generic message informing the user they don't have permissions to perform that action on comments",
	},
	actionNotPermitted: {
		id: 'inline-comments-common.error.action.not.permitted',
		defaultMessage:
			'You can’t perform that action on inline comments in this space. A space admin can modify your permissions.',
		description:
			"A generic message informing the user they don't have permissions to perform that action on comments",
	},
	cannotReplyToResolvedComment: {
		id: 'inline-comments-common.error.cannot.reply.to.resolved',
		defaultMessage:
			'You can’t reply to this comment because it was recently resolved by another user. Reload to refresh now.',
		description: 'A message telling users they cannot reply to a resolved comment',
	},
	cannotUpdateResolvedComment: {
		id: 'inline-comments-common.error.cannot.update.resolved',
		defaultMessage:
			'You can’t edit this comment because it was recently resolved by another user. Reload to refresh now.',
		description: 'A message telling users they cannot edit a resolved comment',
	},
	unableToGenerateStep: {
		id: 'inline-comments-common.error.unable.to.generate.step',
		defaultMessage:
			'You can’t create a comment because something went wrong with your highlight. Close this window and create a new highlight.',
		description: "A message explaining to the user their highlight wasn't correctly created",
	},
	contentOutOfDate: {
		id: 'inline-comments-common.error.content.out.of.date',
		defaultMessage:
			// TODO: replace straight quotes with curly quotes (see go/curlyquotes)
			// eslint-disable-next-line no-restricted-syntax
			"You can't create a comment here because the text has been changed or removed.",
		description: 'A message explaining the content is out of date',
	},
	/**
	 * The message below is duplicated in the next files:
	 * next/packages/resolved-inline-comments-dialog/src/ResolvedInlineCommentsDialogComponent.tsx
	 * packages/confluence-frontend-server/src/components/Comment/PageCommentRenderer.js
	 *
	 * If you are going to change the message please make sure that the same changes are made in the mentioned above files
	 */
	a11yCommentLabel: {
		id: 'inline-comments-common.a11y.comment.wrapper.label',
		defaultMessage: 'Comment by {username}',
		description: 'Label that announced by screen reader when user select comment',
	},
	commentActionsMenuLabel: {
		id: 'inline-comments-common.comment.actions.menu.label',
		defaultMessage: 'More actions',
		description: 'Aria-label for inline comments actions menu button',
	},
	copyLinkTooltip: {
		id: 'inline-comments-common.tooltip.copy.link',
		defaultMessage: 'Copy link to comment',
		description:
			'A message that appears in the tooltip above a button that allows users to copy link to comment',
	},
	linkCopiedTooltip: {
		id: 'inline-comments-common.tooltip.link.has.been.copied',
		defaultMessage: 'Copied!',
		description: 'A message that appears in the tooltip above after user press on copy link button',
	},
	resolve: {
		id: 'inline-comments-common.view.action.resolve',
		defaultMessage: 'Resolve',
		description: 'A clickable link allowing the user to resolve a comment',
	},
	generalCommentTooltip: {
		id: 'inline-comments-common.view.tooltip.general.comment',
		defaultMessage: 'General comment',
		description: 'Tooltip for general comment icon in the comment panel',
	},
	resolved: {
		id: 'inline-comments-common.view.action.resolved',
		defaultMessage: 'Resolved',
		description: 'Text to indicate comment is resolved',
	},
	edit: {
		id: 'inline-comments-common.view.action.edit',
		defaultMessage: 'Edit',
		description: 'A clickable link allowing the user to edit a comment',
	},
	delete: {
		id: 'inline-comments-common.view.action.delete',
		defaultMessage: 'Delete',
		description: 'A clickable link allowing the user to delete a comment',
	},
	reopen: {
		id: 'inline-comments-common.action.reopen',
		defaultMessage: 'Reopen',
		description: 'A clickable link allowing the user to reopen a resolved comment',
	},
	replyPlaceholder: {
		id: 'inline-comments-common.reply.placeholder',
		defaultMessage: 'Reply',
		description: 'Reply placeholder for SSR mode',
	},
	/**
	 * Collaborator Onboarding sample comment
	 */
	sampleAuthor: {
		id: 'inline-comments-common.sample.comment.author',
		defaultMessage: 'Confluence',
		description: 'Author displayed for the sample comment which is the product name Confluence',
	},
	sampleBody: {
		id: 'inline-comments-common.sample.comment.body',
		defaultMessage:
			// TODO: replace straight quotes with curly quotes (see go/curlyquotes)
			// eslint-disable-next-line no-restricted-syntax
			"Highlight content to add an inline comment. All viewers can see these, so it's great for targeted discussion on a specific word or phrase",
		description:
			'Instructions informing the user how to create an inline comment, who can see it, and why it can be useful for detailed conversations',
	},
	sampleReply: {
		id: 'inline-comments-common.sample.reply.input',
		defaultMessage: 'disabled reply input',
		description: 'input label telling the user it is disabled',
	},
	sampleReplyPlaceholder: {
		id: 'inline-comments-common.sample.reply.placeholder',
		defaultMessage: 'Reply',
		description: 'placeholder text in an input field for the user to reply',
	},
	sampleReplyTooltip: {
		id: 'inline-comments-common.sample.reply.tooltip',
		defaultMessage: 'Reply is unavailable for sample comments',
		description: 'tooltip telling the user they cannot add a reply to the sample comment',
	},
	sampleDeleteHeader: {
		id: 'inline-comments-common.sample.comment.delete.header',
		defaultMessage: 'Only you can see this.',
		description: 'Informing the user that they are the only one that cant see this',
	},
	sampleDeleteIcon: {
		id: 'inline-comments-common.sample.comment.delete.icon',
		defaultMessage: 'Delete icon',
		description: 'Label telling the user that this icon is represents Delete',
	},
	sampleDeleteTooltip: {
		id: 'inline-comments-common.sample.comment.delete.tooltip',
		defaultMessage: 'Delete sample comment',
		description: 'Tooltip informing the user clicking would delete the sample comment',
	},
	sampleCloseIconLabel: {
		id: 'inline-comments-common.sample.comment.delete.icon.label',
		defaultMessage: 'Close sample comment',
		description: 'aria-label for the Close sample comment icon',
	},
	collapsedRepliesMore: {
		id: 'inline-comments-common.collapsed.replies.more',
		defaultMessage: '{numCollapsedReplies} more replies',
		description: 'text telling the user how many replies are collapsed',
	},
	unreadInlineComment: {
		id: 'inline-comments-common.unread.inline.comment',
		defaultMessage: 'This is an unread inline comment',
		description: 'label indicating an unread inline comment',
	},
	resolvedByTooltipText: {
		id: 'inline-comments-common.resolved.by.tooltip.text',
		defaultMessage: 'Resolved by {username}',
		description:
			'Tooltip text shown when hovering over the resolved info text, providing context as to which user resolved the comment',
	},
	resolvedByTooltipTextCommentsPanel: {
		id: 'inline-comments-common.resolved.by.tooltip.text.comments.panel',
		defaultMessage: 'By {username}',
		description:
			'Tooltip text shown when hovering over the resolved info text in the comments panel, providing context as to which user resolved the comment',
	},
	resolvedByInfoText: {
		id: 'inline-comments-common.resolved.by.info.text',
		defaultMessage: 'Resolved by {username} {friendlyDate}',
		description:
			'Info text on the resolved comments telling who resolved the comment and when, for example: Resolved by John Doe May 3, 2024',
	},
	resolvedDateInfoText: {
		id: 'inline-comments-common.resolved.date.info.text',
		defaultMessage: 'Resolved {friendlyDate}',
		description:
			'Info text on the resolved comments telling when the comment was resolved, for example: Resolved May 3, 2024',
	},
	deletedCommentActionLabel: {
		id: 'inline-comments-common.deleted.comment.action.label',
		defaultMessage: `This comment has been deleted`,
		description: 'Label for the comment deleted message',
	},
	resolvedText: {
		id: 'inline-comments-common.resolved',
		defaultMessage: '[Resolved]',
		description: 'Text for comment resolved',
	},
	resolvedFlagText: {
		id: 'inline-comments-common.resolved.flag.text',
		defaultMessage: 'Comment resolved',
		description: 'Text in flag when current user resolves a comment',
	},
	deletedText: {
		id: 'inline-comments-common.deleted',
		defaultMessage: '[Deleted]',
		description: 'Text for the comment deleted',
	},
	copyLink: {
		id: 'inline-comments-common.copy.link',
		defaultMessage: 'Copy link',
		description: 'Text for copying link to comment',
	},
	goToCommentCTA: {
		id: 'inline-comments-common.comment.reopened.flag.cta.gotocomment',
		defaultMessage: 'Go to comment',
		description:
			'CTA button text displayed in Flag for users to open the comment in the comments panel that was just resolved or reopened.',
	},
	undoCTA: {
		id: 'inline-comments-common.comment.reopened.flag.cta.undo',
		defaultMessage: 'Undo',
		description:
			'CTA button text displayed in Flag for users to undo the comment resolved or reopened action.',
	},
	edited: {
		id: 'inline-comments-common.edited',
		defaultMessage: 'Edited',
		description:
			'This text is shown as a label next to the comment date to indicate that a comment has been edited',
	},
});
