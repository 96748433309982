import type { DataProxy } from 'apollo-cache';

import { getLogger } from '@confluence/logger';
import {
	ActiveCommentsQuery,
	type ActiveCommentsQueryType,
	type ActiveCommentsQueryVariables,
} from '@confluence/comments-panel-queries';
import { PageMode } from '@confluence/page-utils/entry-points/enums';
import { ADD_PAGE_COMMENT_EXPERIENCE } from '@confluence/experience-tracker';
import type { ExperienceTrackerAPI } from '@confluence/experience-tracker';
import { END } from '@confluence/navdex';
import { fg } from '@confluence/feature-gating';

import { DEFAULT_LIMIT } from './';

import type { CreateGeneralReplyMutation as CreateGeneralReplyMutationData } from './__types__/CreateGeneralReplyMutation';
import type { CreateGeneralCommentMutation as CreateGeneralCommentMutationData } from './__types__/CreateGeneralCommentMutation';
import type { DeleteGeneralCommentMutation as DeleteGeneralCommentMutationData } from './__types__/DeleteGeneralCommentMutation';
import {
	CommentsSectionQuery,
	CommentsSectionWithoutReactionsQuery,
} from './CommentsSectionQuery.graphql';
import type { CommentsSectionQuery as CommentsSectionQueryType } from './__types__/CommentsSectionQuery';
import type { CommentsSectionWithoutReactionsQuery as CommentsSectionWithoutReactionsQueryType } from './__types__/CommentsSectionWithoutReactionsQuery';

const logger = getLogger('page-comments-queries');

export const updateApolloCacheParentCallback =
	(
		actionType: 'delete' | 'create',
		queryVariables: ActiveCommentsQueryVariables,
		isReactionsEnabled?: boolean,
		commentId?: string,
	) =>
	(
		cache: DataProxy,
		result: {
			data?: CreateGeneralCommentMutationData | DeleteGeneralCommentMutationData | boolean | null;
		},
	) => {
		// If the mutation fails, don't do anything
		if (!result || !result.data) {
			return;
		}

		const { pageId, contentStatus } = queryVariables;

		try {
			// Update the comments panel data
			const commentsPanelCacheResponse = cache.readQuery<ActiveCommentsQueryType>({
				query: ActiveCommentsQuery,
				variables: {
					pageId,
					contentStatus,
				},
			});

			if (commentsPanelCacheResponse) {
				const cacheDataClone = { ...commentsPanelCacheResponse };
				const panelNodes = cacheDataClone?.comments?.nodes || [];

				if (actionType === 'delete') {
					// Remove the returned commentId from the cache and reset it
					const idxToRemove = panelNodes.findIndex((comment) => comment?.id === commentId);

					if (idxToRemove !== -1) {
						panelNodes.splice(idxToRemove, 1);
					}
				} else {
					const mutationResult = {
						...(result.data as CreateGeneralCommentMutationData).createFooterComment,
						replies: [],
					};

					// Add the new reply entry to the cache and reset it
					panelNodes.push(mutationResult);
				}

				cache.writeQuery({
					query: ActiveCommentsQuery,
					variables: {
						pageId,
						contentStatus,
					},
					data: cacheDataClone,
				});
			}

			const commentsSectionQuery = isReactionsEnabled
				? CommentsSectionQuery
				: CommentsSectionWithoutReactionsQuery;

			// Update the comments section data
			const commentsSectionCacheResponse = cache.readQuery<
				CommentsSectionQueryType | CommentsSectionWithoutReactionsQueryType
			>({
				query: commentsSectionQuery,
				variables: {
					contentId: pageId,
					offset: '',
					first: DEFAULT_LIMIT,
				},
			});

			if (commentsSectionCacheResponse) {
				// grab the parent comment for the comments panel
				const commentSectionCacheDataClone = { ...commentsSectionCacheResponse };
				const commentEdges = commentSectionCacheDataClone?.comments?.edges || [];

				if (actionType === 'delete') {
					// Find the last index of the top level comment to delete
					const indexToDelete = commentEdges?.findIndex((edge) => edge?.node?.id === commentId);

					if (indexToDelete !== -1) {
						commentEdges.splice(indexToDelete, 1);
					}
				} else {
					if (fg('confluence_frontend_comments_synced_comment_create')) {
						const mutationResult = {
							...(result.data as CreateGeneralCommentMutationData).createFooterComment,
						};

						// As far as I can tell, this is always called w/o reactions enabled
						// This maps the mutation result we get to the cache object that's expected by the comments section query
						const cacheCommentObject = {
							id: mutationResult.id,
							ancestors: [],
							author: {
								...mutationResult.author,
								type: 'known',
							},
							body: mutationResult.body,
							contentStatus: mutationResult.contentStatus,
							links: {
								webui: mutationResult.links.webui,
								// @ts-ignore
								__typename: mutationResult.links.__typename,
							},
							location: mutationResult.location,
							parentId: mutationResult.parentId,
							permissions: mutationResult.permissions,
							version: {
								...mutationResult.version,
								friendlyWhen: 'Just now', // TODO: Maybe replace this with the actual date
								by: {
									type: 'user',
									displayName: '',
									accountId: '',
									__typename: 'User',
								},
								__typename: 'Version',
							},
							__typename: 'Comment',
						};

						// The cache will throw an error if we don't do this ¯\_(ツ)_/¯
						const cacheContentDeepCopy = JSON.parse(JSON.stringify(cacheCommentObject));

						const newEdge = {
							cursor: (commentEdges.length - 1).toString(),
							node: cacheContentDeepCopy as any,
							__typename: 'CommentEdge',
						} as any;

						const insertAfterIndex = commentEdges.length - 1;
						// Put the new reply in the array
						commentEdges.splice(insertAfterIndex + 1, 0, newEdge as any);
					} else {
						const mutationResult = {
							...(result.data as CreateGeneralCommentMutationData).createFooterComment,
							ancestors: [] as { id: string }[],
						};

						// We always add the new comment to the end of the list until we sort (deal with that later)
						const insertAfterIndex = commentEdges.length - 1;

						// Put the new reply in the array
						commentEdges.splice(insertAfterIndex + 1, 0, {
							cursor: (insertAfterIndex + 1).toString(),
							node: mutationResult as any,
							__typename: 'CommentEdge',
						} as any);
					}
				}

				cache.writeQuery({
					query: commentsSectionQuery,
					variables: {
						contentId: pageId,
						offset: '',
						first: DEFAULT_LIMIT,
					},
					data: {
						...commentSectionCacheDataClone,
						comments: {
							...commentSectionCacheDataClone.comments,
							totalCount: commentEdges.length,
							pageInfo: {
								...commentSectionCacheDataClone.comments?.pageInfo,
								endCursor: actionType === 'delete' ? '' : (commentEdges.length - 1).toString(),
								hasNextPage: false,
							},
							edges: commentEdges,
						},
					},
				});
			}
		} catch (err) {
			logger.error`An Error occurred when updating cache for ${actionType} - ${err}`;
		}
	};

export const updateApolloCacheReplyCallback =
	(
		actionType: 'delete' | 'create',
		queryVariables: ActiveCommentsQueryVariables,
		parentCommentId: string,
		isReactionsEnabled?: boolean,
		commentId?: string,
	) =>
	(
		cache: DataProxy,
		result: {
			data?: CreateGeneralReplyMutationData | DeleteGeneralCommentMutationData | boolean | null;
		},
	) => {
		// If the mutation fails, don't do anything
		if (!result || !result.data) {
			return;
		}

		const { pageId, contentStatus } = queryVariables;

		try {
			// Update the comments panel data
			const commentsPanelCacheResponse = cache.readQuery<ActiveCommentsQueryType>({
				query: ActiveCommentsQuery,
				variables: {
					pageId,
					contentStatus,
				},
			});

			if (commentsPanelCacheResponse) {
				// grab the parent comment for the comments panel
				const commentsPanelCacheData: ActiveCommentsQueryType = Object.assign(
					{} as any,
					commentsPanelCacheResponse,
				);
				const commentsPanelParentComment = commentsPanelCacheData?.comments?.nodes?.find(
					(comment) => comment?.id === parentCommentId,
				);

				if (commentsPanelParentComment) {
					if (actionType === 'delete') {
						// Remove the returned commentId from the cache and reset it
						const idxToRemove = commentsPanelParentComment.replies.findIndex(
							(reply) => reply?.id === commentId,
						);

						if (idxToRemove !== -1) {
							commentsPanelParentComment.replies.splice(idxToRemove, 1);
						}
					} else {
						const mutationResult = (result.data as CreateGeneralReplyMutationData)
							.createFooterComment;
						// Add the new reply entry to the cache and reset it
						commentsPanelParentComment.replies.push(mutationResult);
					}

					cache.writeQuery({
						query: ActiveCommentsQuery,
						variables: {
							pageId,
							contentStatus,
						},
						data: commentsPanelCacheData,
					});
				}
			}
		} catch (err) {
			logger.error`An Error occurred when updating comments panel cache for ${actionType} reply - ${err}`;
		}

		try {
			const commentsSectionQuery = isReactionsEnabled
				? CommentsSectionQuery
				: CommentsSectionWithoutReactionsQuery;

			// Update the comments section data
			const commentsSectionCacheResponse = cache.readQuery<
				CommentsSectionQueryType | CommentsSectionWithoutReactionsQueryType
			>({
				query: commentsSectionQuery,
				variables: {
					contentId: pageId,
					offset: '',
					first: DEFAULT_LIMIT,
				},
			});

			if (commentsSectionCacheResponse) {
				// grab the parent comment for the comments panel
				const commentsSectionCacheData = { ...commentsSectionCacheResponse };
				const commentEdges = commentsSectionCacheData?.comments?.edges || [];
				const parentCommentIdx = commentEdges?.findIndex(
					(commentEdge) => commentEdge?.node?.id === parentCommentId,
				);

				if (parentCommentIdx !== -1) {
					if (actionType === 'delete') {
						// Find the last index of the comment to delete
						const indexToDelete = commentEdges?.findIndex((edge) => edge?.node?.id === commentId);

						if (indexToDelete !== -1) {
							commentEdges.splice(indexToDelete, 1);
						}
					} else {
						if (fg('confluence_frontend_comments_synced_comment_create')) {
							const mutationResult = {
								...(result.data as CreateGeneralReplyMutationData).createFooterComment,
							};

							// As far as I can tell, this is always called w/o reactions enabled
							// This maps the mutation result we get to the cache object that's expected by the comments section query
							const cacheCommentObject = {
								id: mutationResult.id,
								ancestors: [
									{
										id: mutationResult.parentId,
										__typename: 'Comment',
									},
								],
								author: {
									...mutationResult.author,
									type: 'known',
								},
								body: mutationResult.body,
								contentStatus: mutationResult.contentStatus,
								links: {
									webui: mutationResult.links.webui,
									// @ts-ignore
									__typename: mutationResult.links.__typename,
								},
								location: mutationResult.location,
								parentId: mutationResult.parentId,
								permissions: mutationResult.permissions,
								version: {
									...mutationResult.version,
									friendlyWhen: 'Just now', // TODO: Maybe replace this with the actual date
									by: {
										type: 'user',
										displayName: '',
										accountId: '',
										__typename: 'User',
									},
									__typename: 'Version',
								},
								__typename: 'Comment',
							};

							// The cache will throw an error if we don't do this ¯\_(ツ)_/¯
							const cacheContentDeepCopy = JSON.parse(JSON.stringify(cacheCommentObject));

							const newEdge = {
								cursor: (commentEdges.length - 1).toString(),
								node: cacheContentDeepCopy as any,
								__typename: 'CommentEdge',
							} as any;

							// Find the last index of the thread to insert the reply
							const threadLastCommentIdx = commentEdges?.findLastIndex(
								(edge) => edge?.node?.parentId === parentCommentId,
							);

							// If we can't find it, the parent has no replies
							const insertAfterIndex =
								threadLastCommentIdx !== -1 ? threadLastCommentIdx : parentCommentIdx;

							// Put the new reply in the array
							commentEdges.splice(insertAfterIndex + 1, 0, newEdge as any);
						} else {
							const mutationResult = {
								...(result.data as CreateGeneralReplyMutationData).createFooterComment,
								ancestors: [{ id: parentCommentId }],
							};

							// Find the last index of the thread to insert the reply
							const threadLastCommentIdx = commentEdges?.findLastIndex(
								(edge) => edge?.node?.parentId === parentCommentId,
							);

							// If we can't find it, the parent has no replies
							const insertAfterIndex =
								threadLastCommentIdx !== -1 ? threadLastCommentIdx : parentCommentIdx;

							// Put the new reply in the array
							commentEdges.splice(insertAfterIndex + 1, 0, {
								cursor: (insertAfterIndex + 1).toString(),
								node: mutationResult as any,
								__typename: 'CommentEdge',
							} as any);
						}
					}

					cache.writeQuery({
						query: commentsSectionQuery,
						variables: {
							contentId: pageId,
							offset: '',
							first: DEFAULT_LIMIT,
						},
						data: {
							...commentsSectionCacheData,
							comments: {
								...commentsSectionCacheData.comments,
								totalCount: commentEdges.length,
								pageInfo: {
									...commentsSectionCacheData.comments?.pageInfo,
									endCursor: actionType === 'delete' ? '' : (commentEdges.length - 1).toString(),
									hasNextPage: false,
								},
								edges: commentEdges,
							},
						},
					});
				}
			}
		} catch (err) {
			logger.error`An Error occurred when updating general comments cache for ${actionType} reply - ${err}`;
		}
	};

export const updateFooterApolloCacheOnEdit = (
	commentId: string,
	newBody: string,
	pageId: string,
	isReactionsEnabled: boolean,
	cache: DataProxy,
) => {
	const commentsSectionQuery = isReactionsEnabled
		? CommentsSectionQuery
		: CommentsSectionWithoutReactionsQuery;

	try {
		// Update the comments section data
		const commentsSectionCacheData = cache.readQuery<
			CommentsSectionQueryType | CommentsSectionWithoutReactionsQueryType
		>({
			query: commentsSectionQuery,
			variables: {
				contentId: pageId,
				offset: '',
				first: DEFAULT_LIMIT,
			},
		});

		if (commentsSectionCacheData) {
			// grab the parent comment for the comments panel
			const commentEdges = commentsSectionCacheData?.comments?.edges || [];
			const commentIdx = commentEdges?.findIndex(
				(commentEdge) => commentEdge?.node?.id === commentId,
			);

			if (
				commentIdx !== undefined &&
				commentIdx !== null &&
				commentIdx !== -1 &&
				commentEdges[commentIdx]?.node?.body &&
				commentEdges[commentIdx]?.node?.version?.number
			) {
				// Find the index to update. TS will throw an error even though we've already checked the index is valid
				// @ts-ignore
				commentEdges[commentIdx].node.body.value = newBody; // Update the body we keep in the footer cache
				// @ts-ignore
				commentEdges[commentIdx].node.version.number += 1; // This is required for the propagated footer version to also be editable
			}

			cache.writeQuery({
				query: commentsSectionQuery,
				variables: {
					contentId: pageId,
					offset: '',
					first: DEFAULT_LIMIT,
				},
				data: {
					...commentsSectionCacheData,
					comments: {
						...commentsSectionCacheData.comments,
						totalCount: commentEdges.length,
						pageInfo: {
							...commentsSectionCacheData.comments?.pageInfo,
							endCursor: Math.random().toString(), // The cache won't update without this. Should be harmless, given hasNextPage is false, so we won't be loading more
							hasNextPage: false,
						},
						edges: commentEdges,
					},
				},
			});
		}
	} catch (err) {
		logger.error`An Error occurred when updating cache for edit footer reply - ${err}`;
	}
};

interface HandleCreateGeneralReplySuccessProps {
	onSuccess: any;
	editCommentQueryId?: string;
	setCommentForEdit?: any;
	data: any;
	createAnalyticsEvent: any;
	pageId: string | undefined;
	pageType: string;
	analyticsSource: string;
	commentId: string | undefined;
	pageMode: PageMode;
	experienceTracker: ExperienceTrackerAPI;
	searchSessionId?: string | null;
}

export const handleCreateReplySuccess = ({
	onSuccess,
	editCommentQueryId,
	setCommentForEdit,
	data,
	createAnalyticsEvent,
	pageId,
	pageType,
	analyticsSource,
	commentId,
	pageMode,
	experienceTracker,
	searchSessionId,
}: HandleCreateGeneralReplySuccessProps) => {
	onSuccess();
	if (editCommentQueryId) {
		setCommentForEdit && setCommentForEdit('');
	}

	const replyCommentInfo = data?.createFooterComment;

	createAnalyticsEvent({
		type: 'sendTrackEvent',
		data: {
			action: 'created',
			actionSubject: 'comment',
			actionSubjectId: replyCommentInfo?.id, // The newly created comment ID
			objectType: pageType,
			objectId: pageId,
			source: analyticsSource,
			attributes: {
				commentType: 'page',
				pageType,
				isLivePage: pageMode === PageMode.LIVE,
				editor: 'v2',
				searchSessionId,
				navdexPointType: END,
				parentCommentId: commentId ?? null, // analytics event schema type expects string or null
			},
		},
	}).fire();

	experienceTracker.succeed({
		name: ADD_PAGE_COMMENT_EXPERIENCE,
	});
};
