import type { StoreActionApi } from 'react-sweet-state';
import { createStore, createHook, createActionsHook, createStateHook } from 'react-sweet-state';

import type { CommentLocationGeneric } from '@confluence/inline-comments-queries';
import type { CommentType } from '@confluence/comments-data';

export enum ViewValues {
	OPEN = 'OPEN',
	UNREAD = 'UNREAD',
	RESOLVED = 'RESOLVED',
}

export enum SortValues {
	PAGE_ORDER = 'PAGE_ORDER',
	MOST_RECENT = 'MOST_RECENT',
}

export type CommentsReadMap = {
	inline: Record<string, Set<string>>;
	general: Record<string, Set<string>>;
};

export type CommentsPanelState = {
	currentView: ViewValues | undefined;
	currentSort: SortValues;
	// NOTE: When there are more types, should use an array or some other data structure
	showInlineComments: boolean;
	showGeneralComments: boolean;
	currentlySelectedCommentMarkerRef: string | undefined;
	commentsMarkedAsRead: CommentsReadMap; // maps annotation to set of comment IDs
	initialDataLoadedForViewMap: Record<ViewValues, boolean>;
	replyToCommentId: string | undefined;
	// Keep track of the which threads the user is currently viewing (used for keyboard shortcuts)
	currentlyRenderedThreads: CommentLocationGeneric[];
};

const initialState: CommentsPanelState = {
	currentView: undefined,
	currentSort: SortValues.PAGE_ORDER,
	showInlineComments: true,
	showGeneralComments: true,
	currentlySelectedCommentMarkerRef: undefined,
	commentsMarkedAsRead: {
		inline: {},
		general: {},
	},
	initialDataLoadedForViewMap: {
		[ViewValues.UNREAD]: false,
		[ViewValues.OPEN]: false,
		[ViewValues.RESOLVED]: false,
	},
	replyToCommentId: undefined,
	currentlyRenderedThreads: [],
};

// Actions for managing the comments state in the comments panel
const actions = {
	setCurrentView:
		(view: ViewValues | undefined) =>
		({ setState }: StoreActionApi<CommentsPanelState>) => {
			setState({ currentView: view });
		},
	setCurrentSort:
		(sort: SortValues) =>
		({ setState }: StoreActionApi<CommentsPanelState>) => {
			setState({ currentSort: sort });
		},
	setShowInlineComments:
		(showInline: boolean) =>
		({ setState }: StoreActionApi<CommentsPanelState>) => {
			setState({ showInlineComments: showInline });
		},
	setShowGeneralComments:
		(showGeneral: boolean) =>
		({ setState }: StoreActionApi<CommentsPanelState>) => {
			setState({ showGeneralComments: showGeneral });
		},
	setCurrentlySelectedCommentMarkerRef:
		(markerRef: string | undefined) =>
		({ setState }: StoreActionApi<CommentsPanelState>) => {
			setState({ currentlySelectedCommentMarkerRef: markerRef });
		},
	setCommentsMarkedAsRead:
		({
			threadKey,
			commentIds,
			commentType,
		}: {
			threadKey?: string;
			commentIds?: string[];
			commentType: CommentType;
		}) =>
		({ setState, getState }: StoreActionApi<CommentsPanelState>) => {
			const { commentsMarkedAsRead } = getState();
			if (threadKey && commentIds) {
				// Add the new comment IDs to the existing set for the specific parent
				const updatedCommentIds = { ...commentsMarkedAsRead };
				const existingIdsSet = updatedCommentIds[commentType][threadKey] || new Set();
				commentIds.forEach((id) => existingIdsSet.add(id));
				// Update the map with the new set of IDs
				updatedCommentIds[commentType][threadKey] = existingIdsSet;
				setState({ commentsMarkedAsRead: updatedCommentIds });
			}
		},
	clearCommentsMarkedAsRead:
		() =>
		({ setState }: StoreActionApi<CommentsPanelState>) => {
			setState({ commentsMarkedAsRead: { inline: {}, general: {} } });
		},
	setInitialDataLoadedForView:
		({ viewToSetLoaded }: { viewToSetLoaded: ViewValues }) =>
		({ getState, setState }: StoreActionApi<CommentsPanelState>) => {
			const { initialDataLoadedForViewMap } = getState();

			initialDataLoadedForViewMap[viewToSetLoaded] = true;

			setState({ initialDataLoadedForViewMap });
		},
	setReplyToCommentId:
		(commentIdToReplyTo?: string) =>
		({ setState }: StoreActionApi<CommentsPanelState>) => {
			setState({ replyToCommentId: commentIdToReplyTo });
		},
	resetInitialDataLoadedMap:
		() =>
		({ setState }: StoreActionApi<CommentsPanelState>) => {
			setState({
				initialDataLoadedForViewMap: {
					[ViewValues.UNREAD]: false,
					[ViewValues.OPEN]: false,
					[ViewValues.RESOLVED]: false,
				},
			});
		},
	setCurrentlyRenderedThreads:
		(threads: CommentLocationGeneric[]) =>
		({ setState }: StoreActionApi<CommentsPanelState>) => {
			setState({ currentlyRenderedThreads: threads });
		},
};

const CommentsStore = createStore({
	initialState,
	actions,
	name: 'CommentsStore',
});

export const useCommentsPanel = createHook(CommentsStore);
export const useCommentsPanelActions = createActionsHook(CommentsStore);
export const useCommentsPanelState = createStateHook(CommentsStore);
