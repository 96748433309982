import type { AnalyticsEventPayload, UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { AnnotationUpdateEmitter } from '@atlaskit/editor-common/annotation';
import { AnnotationUpdateEvent } from '@atlaskit/editor-common/types';
import { AnnotationMarkStates, AnnotationTypes } from '@atlaskit/adf-schema';

import { markErrorAsHandled } from '@confluence/graphql';
import type { ExperienceTrackerAPI } from '@confluence/experience-tracker';
import { PageMode } from '@confluence/page-utils/entry-points/enums';
import { END } from '@confluence/navdex';
import { AnalyticsSource } from '@confluence/comments-util/entry-points/analytics';

import { getTranslatedError, parseError, isUnexpectedError } from './inlineCommentsErrorUtils';
import { InlineCommentFramework } from './enum/InlineCommentFramework';
import { getTargetNodeType } from './analyticsUtils';

export const handleResolveSuccess = ({
	commentId,
	parentCommentMarkerRef,
	pageId,
	pageMode,
	eventEmitter,
	source,
	removeUnresolvedInlineComment,
	createAnalyticsEvent,
	getInlineNodeTypes,
	setEditorViewProps,
}: {
	commentId?: string;
	parentCommentMarkerRef: string;
	pageId: string;
	pageMode: PageMode;
	eventEmitter?: AnnotationUpdateEmitter;
	source: AnalyticsSource.COMMENTS_PANEL | 'editPageScreen' | 'viewPageScreen';
	removeUnresolvedInlineComment: (unresolvedAnnotationId: string | null) => void;
	createAnalyticsEvent: (payload: AnalyticsEventPayload) => UIAnalyticsEvent;
	getInlineNodeTypes?: (annotationId: string) => string[] | undefined;
	setEditorViewProps?: (viewProps: null) => void;
}) => {
	// Update the unresolved inline comments count
	removeUnresolvedInlineComment(parentCommentMarkerRef);

	createAnalyticsEvent({
		type: 'sendTrackEvent',
		data: {
			action: 'resolved',
			actionSubject: 'comment',
			actionSubjectId: commentId,
			objectType: 'page',
			objectId: pageId,
			source,
			attributes: {
				commentType: 'inline',
				mode: pageMode,
				framework: InlineCommentFramework.ANNOTATION_PROVIDER,
				navdexPointType: END,
				inlineNodeTypes:
					parentCommentMarkerRef && getInlineNodeTypes
						? getInlineNodeTypes(parentCommentMarkerRef)
						: undefined,
				targetNodeType: getTargetNodeType(parentCommentMarkerRef, pageMode !== PageMode.VIEW),
			},
		},
	}).fire();

	// Emit the event to mark the annotation as resolved
	if (pageMode === PageMode.VIEW) {
		eventEmitter?.emit(AnnotationUpdateEvent.SET_ANNOTATION_STATE, {
			[parentCommentMarkerRef]: {
				id: parentCommentMarkerRef,
				annotationType: AnnotationTypes.INLINE_COMMENT,
				state: AnnotationMarkStates.RESOLVED,
			},
		});
	} else {
		eventEmitter?.emit('resolve', parentCommentMarkerRef);
		setEditorViewProps && setEditorViewProps(null);
	}
};

export const handleDeleteSuccess = ({
	commentId,
	annotationId,
	pageId,
	pageMode,
	isReply,
	source,
	inlineNodeTypes,
	removeUnresolvedInlineComment,
	createAnalyticsEvent,
	onSuccess,
}: {
	commentId?: string;
	annotationId?: string;
	pageId: string;
	pageMode: PageMode;
	isReply: boolean;
	source: AnalyticsSource.COMMENTS_PANEL | 'editPageScreen' | 'viewPageScreen';
	inlineNodeTypes?: string[];
	createAnalyticsEvent: (payload: AnalyticsEventPayload) => UIAnalyticsEvent;
	removeUnresolvedInlineComment: (unresolvedAnnotationId: string | null) => void;
	onSuccess: (annotationId: string) => void;
}) => {
	if (!annotationId) {
		throw new Error('No annotationId for delete');
	}

	// Update the unresolved inline comments count
	removeUnresolvedInlineComment(annotationId);

	createAnalyticsEvent({
		type: 'sendTrackEvent',
		data: {
			action: 'deleted',
			actionSubject: 'comment',
			actionSubjectId: commentId,
			objectType: 'page',
			objectId: pageId,
			source,
			attributes: {
				commentType: 'inline',
				mode: pageMode,
				framework: InlineCommentFramework.ANNOTATION_PROVIDER,
				inlineNodeTypes,
				isReply,
				targetNodeType: getTargetNodeType(annotationId, pageMode !== PageMode.VIEW),
			},
		},
	}).fire();

	// Call the function passed in to do success specific things for each iteration
	onSuccess(annotationId);
};

// TODO: Move me and the translation logic to a shared component now that both
// general and inline comments use this logic to handle mutation failures
export const handleMutationFailure = ({
	experienceTracker,
	experienceName,
	error,
	commentId,
	shouldReturnError = false,
}: {
	experienceTracker: ExperienceTrackerAPI;
	experienceName: string;
	error: Error;
	commentId?: string;
	shouldReturnError?: boolean;
}) => {
	// get a truncated graphql error message
	const { message } = parseError(error);
	const translatedError = getTranslatedError(message, commentId);

	if (isUnexpectedError(translatedError)) {
		experienceTracker.stopOnError({
			name: experienceName,
			error,
		});
	} else {
		// we currently display a generic message to the user whenever an error occurs
		// These errors will not be reported to splunk/signalfx through experience tracking as they are expected
		markErrorAsHandled(error);
	}

	if (shouldReturnError) {
		// CommentEditor expects a translated error message otherwise it fails
		// TODO: CommentEditor should likely just receive the translated message
		// rather than expect the object needed for FormattedMessage
		return Promise.reject({
			error: translatedError,
		});
	}
};
