import React from 'react';
import { styled, keyframes } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import CustomThemeButton from '@atlaskit/button/custom-theme-button';
import type { CustomThemeButtonProps } from '@atlaskit/button/custom-theme-button';

import { AUTO_DISMISS_RESOLVE_NOTIFICATION_ANIMATION_SPEED } from './inlineCommentConstants';
import type { InlineCommentsMode } from './inlineCommentsTypes';

type SidebarContainerProps = {
	offset: number;
	mode?: InlineCommentsMode;
	isAutoDismissResolve?: boolean;
	isSticky?: boolean;
};

type NewReplyContainerProps = {
	mode: InlineCommentsMode;
	isCommentsPanel?: boolean;
};

const slideOut = keyframes({
	from: { transform: 'translateX(0)' },
	to: { transform: 'translateX(280px)' },
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ActionsContainer = styled.ul({
	display: 'flex',
	flexWrap: 'wrap',
	alignItems: 'center',
	font: token('font.body.small'),
	padding: `${token('space.100')} 0 0 0`,
	minHeight: 28,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ActionText = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':hover span': {
		textDecoration: 'underline',
		cursor: 'pointer',
	},
});

// Editor feedback button has a z-index of 460; Table sticky header has a z-index of 500; Renderer Page Toolbar has a z-index of 12
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
export const SidebarContainer = styled.div<SidebarContainerProps>(`
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766 */ ''}
    z-index: ${(props: SidebarContainerProps) => (props.mode === 'view' ? 11 : 501)};
    border: 1px solid ${token('color.border')};
    border-radius: 3px;
    box-shadow: ${token('elevation.shadow.overflow')};
    background-color: ${token('elevation.surface')};
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766 */ ''}
    right: ${(props: SidebarContainerProps) => (props.isSticky ? '52' : '12')}px;
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766 */ ''}
    position: ${(props: SidebarContainerProps) => (props.isSticky ? 'fixed' : 'absolute')};
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766 */ ''}
    top: ${(props: SidebarContainerProps) => props.offset}px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin-bottom: 100px;
    width: 280px;
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766 */ ''}
    ${(props: SidebarContainerProps) =>
			Boolean(props.isAutoDismissResolve) &&
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			`animation: ${slideOut} ${AUTO_DISMISS_RESOLVE_NOTIFICATION_ANIMATION_SPEED}ms ease-in-out;`}
  `);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ReplyListContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	backgroundColor: `${token('elevation.surface')}`,

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> div[data-comment-id]': {
		borderTop: `1px solid ${token('color.border')}`,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const NewReplyContainer = styled.div<NewReplyContainerProps>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	borderTop: (props: NewReplyContainerProps) =>
		props.isCommentsPanel ? '' : `1px solid ${token('color.border')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	padding: (props: NewReplyContainerProps) => {
		const basePadding =
			props.mode === 'view'
				? `${token('space.150')} ${token('space.200')}`
				: `${token('space.100')} ${token('space.150')}`;
		return props.isCommentsPanel ? '0' : basePadding;
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'[placeholder="Reply"]': {
		height: 30,
		padding: token('space.050'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ResolvedNotificationContainer = styled.div(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	`
  display: flex;
  padding: ${token('space.400')} 0;
  align-items: center;
  flex-direction: column;
  & p {
    height: 36px;
    width: 205px;
    color: ${token('color.text')};
	font: ${token('font.body.UNSAFE_small')};
    text-align: center;
  }
  & a {
    margin-top: ${token('space.150')};
    cursor: pointer;
    font: ${token('font.body.UNSAFE_small')};
    text-align: center;
  }
`,
);

// View mode styled components
type EditorContainerProps = {
	isReply?: boolean;
	mode: InlineCommentsMode;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const EditorContainer = styled.div<EditorContainerProps>(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	`
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766 */ ''}
  ${(props: EditorContainerProps) =>
		!props.isReply &&
		`
        padding: ${token('space.150')};
        border-bottom: 1px solid ${token('color.border')};
      `}
`,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const NavigationContainer = styled.div(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	`
  display: flex;
  > span:last-child {
    padding-left: 10px;
    vertical-align: text-top;
    color: ${token('color.text.subtle')};
  }
`,
);

interface InlineHeaderButtonProps extends CustomThemeButtonProps {
	isSSRFocusedComment?: boolean;
}

export const HEADER_BUTTON_HEIGHT = '24px';
export const HEADER_BUTTON_WIDTH = '28px';

const inlineHeaderButtonTheme =
	(isSSRFocusedComment?: boolean) => (currentTheme: any, themeProps: any) => {
		const theme = currentTheme(themeProps);

		return {
			...theme,
			buttonStyles: {
				...theme.buttonStyles,
				background: 'none',
				borderRadius: '4px',
				height: HEADER_BUTTON_HEIGHT,
				width: HEADER_BUTTON_HEIGHT,
				'&:hover': {
					backgroundColor: `${token('elevation.surface.hovered')}`,
				},
				'&:focus': {
					boxShadow: `0 0 0 2px ${token('color.border.focused')}`,
				},
				cursor: isSSRFocusedComment ? 'not-allowed' : 'pointer',
				'*': {
					color: isSSRFocusedComment ? `${token('color.text.subtlest')}` : `inherit`,
				},
			},
		};
	};

export const InlineHeaderButton = (props: InlineHeaderButtonProps) => (
	<CustomThemeButton theme={inlineHeaderButtonTheme(props.isSSRFocusedComment)} {...props} />
);

type SidebarHeaderProps = {
	shouldShowNavigation?: boolean;
	isReattachHeader?: boolean;
};

type LoadingSkeletonContainerProps = {
	showSkeleton: boolean;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SidebarHeader = styled.div<SidebarHeaderProps>(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	`
  position: relative;
  display: flex;
  padding-left: ${token('space.150')};
  padding-right: ${token('space.150')};
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766 */ ''}
  justify-content: ${(props: SidebarHeaderProps) =>
		props.shouldShowNavigation ? 'space-between' : 'flex-end'};
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766 */ ''}
  ${(props: SidebarHeaderProps) =>
		props.shouldShowNavigation && `height: 25px; padding-top: ${token('space.150')};`}
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766 */ ''}
  ${(props: SidebarHeaderProps) =>
		props.isReattachHeader && `height: 25px; padding-top: ${token('space.200')};`}
`,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LoadingSkeletonContainer = styled(SidebarContainer)<LoadingSkeletonContainerProps>(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	`
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766 */ ''}
  display: ${(props: LoadingSkeletonContainerProps) => (props.showSkeleton ? `block` : `none`)};
  width: 280px;
  height: 216px;
`,
);

type TopContainerProps = {
	showNavigation?: boolean;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TopContainer = styled.div<TopContainerProps>({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginTop: (props: TopContainerProps) =>
		props.showNavigation ? `${token('space.400')}` : `${token('space.0')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const CloseButtonContainer = styled.div({
	position: 'absolute',
	right: `${token('space.150')}`,
	zIndex: 1,
	top: `${token('space.150')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ViewAllCommentsContainer = styled.div({
	position: 'absolute',
	top: `${token('space.200')}`,
	left: `${token('space.200')}`,
	display: 'flex',
	gap: `${token('space.075')}`,
	alignItems: 'center',
	justifyContent: 'center',
});

const closeButtonTheme = (currentTheme: any, themeProps: any) => {
	const theme = currentTheme(themeProps);

	return {
		...theme,
		buttonStyles: {
			...theme.buttonStyles,
			height: HEADER_BUTTON_HEIGHT,
			width: HEADER_BUTTON_WIDTH,
		},
	};
};

export const CloseButton = (props: CustomThemeButtonProps) => (
	<CustomThemeButton theme={closeButtonTheme} {...props} />
);
