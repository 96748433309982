import React, { type ComponentType } from 'react';
import type { ExecutionResult, MutationFunctionOptions } from 'react-apollo';

import { AnnotationUpdateEvent } from '@atlaskit/editor-common/types';
import type { AnnotationUpdateEmitter } from '@atlaskit/editor-common/annotation';
import type { AnnotationInfo } from '@atlaskit/editor-plugin-annotation';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';

import {
	REPLY_TO_INLINE_COMMENT_EXPERIENCE,
	ADD_PAGE_COMMENT_EXPERIENCE,
} from '@confluence/experience-tracker';
import { scrollCommentIntoView, scrollToElementInEditor } from '@confluence/comments-util';
import {
	handleMutationFailure,
	handleCreateReplySuccess as handleCreateInlineReplySuccess,
	updateApolloCacheCallback as updateInlineApolloCacheCallback,
	getCommentCreationLocation,
} from '@confluence/inline-comments-common/entry-points/inlineCommentsUtils';
import { fg } from '@confluence/feature-gating';
import { PageMode } from '@confluence/page-utils/entry-points/enums';
import type {
	CommentLocation,
	CreateInlineReplyMutationType,
	CreateInlineReplyMutationVariables,
} from '@confluence/inline-comments-queries';
import type { GraphQLContentStatus } from '@confluence/comments-panel-queries';
import {
	updateApolloCacheReplyCallback as updateGeneralApolloCacheCallback,
	handleCreateReplySuccess as handleCreateGeneralReplySuccess,
} from '@confluence/page-comments-queries/entry-points/pageCommentUtils';
import { CommentType } from '@confluence/comments-data';
import type { CommentData, ReplyData } from '@confluence/comments-data';
import type { ExperienceTrackerAPI } from '@confluence/experience-tracker';
import {
	Platform,
	ContentRepresentation,
} from '@confluence/page-comments-queries/entry-points/__types__/CreateGeneralReplyMutation';
import type {
	CreateGeneralReplyMutationData,
	CreateGeneralReplyMutationVariables,
} from '@confluence/page-comments-queries/entry-points/__types__/CreateGeneralReplyMutation';

import type { CommentThreadProps } from '../components/CommentThread';

export enum ViewReplyOptions {
	ALL = 'ALL', // shows all replies
	DEFAULT = 'DEFAULT', // shows up to 3 unread comments
}

type HandleCommentThreadSelectionProps = {
	parentComment: CommentData;
	pageMode: PageMode;
	setCurrentlySelectedCommentMarkerRef: (parentCommentMarkerRef: string | undefined) => void;
	eventEmitter: AnnotationUpdateEmitter;
};

type HandlePanelSelectionAndScrollProps = {
	threadKey: string | null;
	isResolved: boolean;
	pageMode: PageMode;
	setCurrentlySelectedCommentMarkerRef: (parentCommentMarkerRef: string | undefined) => void;
	eventEmitter: AnnotationUpdateEmitter;
	commentType: CommentType;
};

export const handleCommentThreadSelection = ({
	parentComment,
	pageMode,
	setCurrentlySelectedCommentMarkerRef,
	eventEmitter,
}: HandleCommentThreadSelectionProps) => {
	const threadKey =
		parentComment.type === CommentType.INLINE
			? (parentComment.location as CommentLocation).inlineMarkerRef
			: parentComment.id;
	const isResolved = !parentComment.isOpen;

	handlePanelSelectionAndScroll({
		threadKey,
		isResolved,
		pageMode,
		setCurrentlySelectedCommentMarkerRef,
		eventEmitter,
		commentType: parentComment.type,
	});
};

export const handlePanelSelectionAndScroll = ({
	threadKey,
	isResolved,
	pageMode,
	setCurrentlySelectedCommentMarkerRef,
	eventEmitter,
	commentType,
}: HandlePanelSelectionAndScrollProps) => {
	if (threadKey && !isResolved) {
		// We only scroll the document for non-resolved inline comments
		if (commentType === CommentType.INLINE) {
			setCurrentlySelectedCommentMarkerRef(threadKey);
			const commentElement = document.getElementById(threadKey);
			if (commentElement) {
				if (pageMode === PageMode.EDIT || pageMode === PageMode.LIVE) {
					scrollToElementInEditor({ targetElement: commentElement, viewingRoomOffset: -300 });
					eventEmitter.emit('setselectedannotation', threadKey);
				} else {
					const commentThreadContainerId = `comment-thread-${threadKey}-container`;
					eventEmitter.emit(AnnotationUpdateEvent.SET_ANNOTATION_FOCUS, {
						annotationId: threadKey,
					});
					scrollCommentIntoView({
						commentElement,
						isFabricPage: true,
						useInstantScroll: false,
						commentThreadContainerId,
						isCommentsPanel: true,
					});
				}
			}
		}

		// Page comment case
		if (
			commentType === CommentType.GENERAL &&
			fg('confluence-frontend-comments-panel-design-update')
		) {
			eventEmitter.emit('setselectedannotation', '');
			const commentElement = document.getElementById(`comment-${threadKey}`);
			if (commentElement) {
				if (pageMode === PageMode.EDIT || pageMode === PageMode.LIVE) {
					scrollToElementInEditor({ targetElement: commentElement, viewingRoomOffset: -300 });
				} else {
					const commentThreadContainerId = `comment-thread-${threadKey}-container`;

					scrollCommentIntoView({
						commentElement,
						isFabricPage: true,
						useInstantScroll: false,
						commentThreadContainerId,
						isCommentsPanel: true,
					});
				}
			}
		}
	}
};

type SaveProps = {
	pageId: string;
	parentCommentId: string;
	cloudId: string;
	adf: object;
	onSuccess: () => void;
	pageMode: PageMode;
	editCommentQueryId: string;
	setCommentForEdit: React.Dispatch<React.SetStateAction<string>>;
	createAnalyticsEvent: CreateUIAnalyticsEvent;
	pageType: string;
	experienceTracker: ExperienceTrackerAPI;
	createInlineReplyFn: (
		options?:
			| MutationFunctionOptions<CreateInlineReplyMutationType, CreateInlineReplyMutationVariables>
			| undefined,
	) => Promise<ExecutionResult<CreateInlineReplyMutationType>>;
	createGeneralReplyFn: (
		options?:
			| MutationFunctionOptions<CreateGeneralReplyMutationData, CreateGeneralReplyMutationVariables>
			| undefined,
	) => Promise<ExecutionResult<CreateGeneralReplyMutationData>>;
	selectedAnnotation: AnnotationInfo;
	addReplyToCommentThread: (ref: string, reply: ReplyData, commentType: CommentType) => void;
	threadKey: string;
	updateReadCommentsListState: (
		transform: (prevReadCommentsListState: Set<string>) => Set<string>,
	) => void;
	commentType: CommentType;
	searchSessionId?: string | null;
	isReactionsEnabled?: boolean;
};

export const onSaveReply = async ({
	pageId,
	parentCommentId,
	cloudId,
	adf,
	onSuccess,
	pageMode,
	editCommentQueryId,
	setCommentForEdit,
	createAnalyticsEvent,
	pageType,
	experienceTracker,
	createInlineReplyFn,
	createGeneralReplyFn,
	selectedAnnotation,
	addReplyToCommentThread,
	threadKey,
	updateReadCommentsListState,
	commentType,
	searchSessionId,
	isReactionsEnabled,
}: SaveProps) => {
	const isInlineComment = commentType === CommentType.INLINE;
	const isEditor = pageMode === PageMode.EDIT;

	const createReplyFn = isInlineComment
		? createInlineReplyFn({
				variables: {
					input: {
						containerId: pageId,
						parentCommentId,
						commentBody: {
							value: JSON.stringify(adf),
							representationFormat: ContentRepresentation.ATLAS_DOC_FORMAT,
						},
						createdFrom: getCommentCreationLocation(isEditor, pageMode === PageMode.LIVE),
					},
					pageId,
				},
				update: updateInlineApolloCacheCallback(
					'create',
					{
						pageId,
						contentStatus: ['CURRENT', 'DRAFT'] as GraphQLContentStatus[],
						annotationId: threadKey,
					},
					parentCommentId,
				),
			})
		: createGeneralReplyFn({
				variables: {
					pageId,
					cloudId,
					input: {
						commentBody: {
							value: JSON.stringify(adf),
							representationFormat: ContentRepresentation.ATLAS_DOC_FORMAT,
						},
						commentSource: Platform.WEB,
						containerId: pageId,
						parentCommentId,
					},
				},
				update: updateGeneralApolloCacheCallback(
					'create',
					{
						pageId,
						contentStatus: ['CURRENT', 'DRAFT'] as GraphQLContentStatus[],
					},
					parentCommentId,
					isReactionsEnabled,
				),
			});

	return createReplyFn
		.then(({ data }: any) => {
			const replyResult = isInlineComment ? data.replyInlineComment : data.createFooterComment;
			const reply: ReplyData = {
				isUnread: false,
				type: isInlineComment ? CommentType.INLINE : CommentType.GENERAL,
				...replyResult,
			};
			updateReadCommentsListState((prev) => new Set([...prev, reply.id]));
			addReplyToCommentThread(threadKey, reply, commentType);

			if (isInlineComment) {
				handleCreateInlineReplySuccess({
					onSuccess,
					editCommentQueryId,
					setCommentForEdit,
					data,
					createAnalyticsEvent,
					pageId,
					pageType,
					analyticsSource: isEditor ? 'editPageCommentsPanel' : 'viewPageCommentsPanel',
					commentId: parentCommentId,
					pageMode,
					selectedAnnotation,
					getInlineNodeTypes: undefined,
					experienceTracker,
					isEditor,
				});
			} else {
				handleCreateGeneralReplySuccess({
					onSuccess,
					editCommentQueryId,
					setCommentForEdit,
					data,
					createAnalyticsEvent,
					pageId,
					pageType,
					analyticsSource: isEditor ? 'editPageCommentsPanel' : 'viewPageCommentsPanel',
					commentId: parentCommentId,
					pageMode,
					experienceTracker,
					searchSessionId,
				});
			}
		})
		.catch((error: any) => {
			return handleMutationFailure({
				experienceTracker,
				experienceName: isInlineComment
					? REPLY_TO_INLINE_COMMENT_EXPERIENCE
					: ADD_PAGE_COMMENT_EXPERIENCE,
				error,
				commentId: parentCommentId,
				shouldReturnError: true,
			});
		});
};

export type CommentThreadMouseEnterParams = {
	id?: string;
	threadKey?: string;
	setIsThreadHovered: React.Dispatch<React.SetStateAction<boolean>>;
	setHoveredCommentId: React.Dispatch<React.SetStateAction<string | undefined>>;
	pageMode: PageMode;
	eventEmitter: AnnotationUpdateEmitter;
};

export const handleCommentThreadMouseEnter = ({
	id,
	threadKey,
	setIsThreadHovered,
	setHoveredCommentId,
	pageMode,
	eventEmitter,
}: CommentThreadMouseEnterParams) => {
	if (threadKey) {
		setIsThreadHovered(true);
		id && setHoveredCommentId(id);
		if (fg('confluence_frontend_comments_panel_v2')) {
			if (pageMode === PageMode.EDIT || pageMode === PageMode.LIVE) {
				eventEmitter.emit('sethoveredannotation', threadKey);
			} else {
				eventEmitter.emit(AnnotationUpdateEvent.SET_ANNOTATION_HOVERED, {
					annotationId: threadKey,
				});
			}
		}
	}
};

export const handleCommentThreadMouseLeave = ({
	threadKey,
	setIsThreadHovered,
	setHoveredCommentId,
	pageMode,
	eventEmitter,
}: CommentThreadMouseEnterParams) => {
	if (threadKey) {
		setIsThreadHovered(false);
		setHoveredCommentId(undefined);
		if (pageMode === PageMode.EDIT || pageMode === PageMode.LIVE) {
			eventEmitter.emit('removehoveredannotation', threadKey);
		} else {
			eventEmitter.emit(AnnotationUpdateEvent.REMOVE_ANNOTATION_HOVERED, {
				annotationId: threadKey,
			});
		}
	}
};

export const getCommentThreadsToBatch: (
	comments: CommentData[],
	passThroughProps: any,
	CommentThreadComponent: ComponentType<CommentThreadProps>,
	transitionIndexWithinBatch: number | undefined,
) => React.JSX.Element[] = (
	comments,
	passThroughProps,
	CommentThreadComponent,
	transitionIndexWithinBatch,
) =>
	comments.map((parentComment, index, array) => {
		const isLastComment = index === array.length - 1;

		const shouldRenderCommentTypeSeparator = index === transitionIndexWithinBatch;

		return (
			<CommentThreadComponent
				key={parentComment.id}
				parentComment={parentComment}
				{...passThroughProps}
				shouldRenderCommentThreadSeperator={!isLastComment}
				shouldRenderCommentTypeSeparator={shouldRenderCommentTypeSeparator}
			/>
		);
	});

export const hasUnreadHiddenReply = (reply: ReplyData, hiddenReplies: ReplyData[]) => {
	return hiddenReplies.some(
		(hiddenReply: ReplyData) => hiddenReply.id === reply.id && hiddenReply.isUnread,
	);
};

// Helper function to safely parse dates
export const getValidDate = (dateString?: string): Date => {
	if (!dateString) {
		return new Date(0); // Return earliest possible date if missing
	}

	// Ensure UTC timezone. Some createdAt values are missing the UTC timezone identifier, and some have it
	const normalizedDate = dateString.endsWith('Z') ? dateString : `${dateString}Z`;

	// Try to parse the date, return earliest date if invalid
	const parsed = new Date(normalizedDate);
	return isNaN(parsed.getTime()) ? new Date(0) : parsed;
};
